import React, { useState } from "react";
import { Button, Form, Radio } from "semantic-ui-react";
import Navbar from "../components/navbar";

const FileDown = () => {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        fileType: "photo",
        file: null,
    });
    const [uploadStatus, setUploadStatus] = useState(""); // Pour afficher le statut d'upload

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérification avant d'envoyer les données
        if (!formData.file) {
            setUploadStatus("Veuillez sélectionner un fichier avant de soumettre.");
            return;
        }

        const data = new FormData();
        data.append("name", formData.name);
        data.append("description", formData.description);
        data.append("fileType", formData.fileType);
        data.append("file", formData.file);

        // Debug : Afficher les données dans la console
        for (let [key, value] of data.entries()) {
            console.log(`${key}: ${value}`);
        }

        // Afficher un message d'attente
        setUploadStatus("Upload en cours...");

        try {
            const response = await fetch("https://api.daurelthomas.fr/api/upload", {
                method: "POST",
                headers: {
                    Accept: "application/json", // Accepter le JSON comme réponse
                },
                body: data,
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Erreur du serveur :", errorData);
                setUploadStatus("Erreur lors de l'upload. Vérifiez vos données.");
                return;
            }

            const result = await response.json();
            console.log("File upload result:", result);

            // Mettre à jour le message d'upload une fois le fichier téléchargé avec succès
            setUploadStatus(`Fichier téléchargé avec succès ! Path: ${result.path}`);
        } catch (error) {
            console.error("Erreur lors de la requête :", error);
            setUploadStatus("Une erreur est survenue. Vérifiez votre connexion.");
        }
    };

    return (
        <div>
            <Navbar>
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-12 col-md-8 mx-auto">
                            <Form onSubmit={handleSubmit} className="p-4 border rounded bg-light">
                                <h3 className="mb-3">Télécharger un document</h3>

                                {uploadStatus && (
                                    <div className="alert alert-info" role="alert">
                                        {uploadStatus}
                                    </div>
                                )}

                                <Form.Field>
                                    <label>A qui appartient le document ?</label>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Entrez un nom"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Description du fichier</label>
                                    <textarea
                                        name="description"
                                        placeholder="Entrez une description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Type de fichier</label>
                                    <Radio
                                        label="Photo"
                                        name="fileType"
                                        value="photo"
                                        checked={formData.fileType === "photo"}
                                        onChange={(_, { value }) => setFormData({ ...formData, fileType: value })}
                                    />
                                    <Radio
                                        label="PDF"
                                        name="fileType"
                                        value="pdf"
                                        checked={formData.fileType === "pdf"}
                                        onChange={(_, { value }) => setFormData({ ...formData, fileType: value })}
                                        style={{ marginLeft: "1rem" }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Fichier</label>
                                    <input
                                        type="file"
                                        name="file"
                                        accept={formData.fileType === "photo" ? "image/*" : "application/pdf"}
                                        onChange={handleFileChange}
                                        required
                                    />
                                </Form.Field>

                                <Button type="submit" primary>
                                    Télécharger
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    );
};

export default FileDown;
