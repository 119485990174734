import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import PlanningApril from './pages/planningApril';
import InfoConnexion from './pages/InfoConnexion';
import Ranking from './pages/ranking';
import FileDown from './pages/downloadDocument';
import App from './App';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/accueil" element={<Home />} />
        <Route path="/planning-April" element={<PlanningApril />} />
        <Route path="/information-connexion" element={<InfoConnexion />} />
        <Route path="/classement" element={<Ranking />} />
        <Route path="/document" element={<FileDown />} />
      </Routes>
    </Router>
  </React.StrictMode>,

);
