import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css';
import { Table, Input, Header, Dropdown, Modal, Button } from 'semantic-ui-react';
import '../assets/style/style.scss';
import Navbar from '../components/navbar'; // Assurez-vous que votre Navbar est correctement importée


const seasonOptions = [
    { key: '2024', text: 'Saison 2024/2025', value: '2024' },
    { key: '2023', text: 'Saison 2023/2024', value: '2023' },
];

const genderOptions = [
    { key: 'male', text: 'Homme', value: 'male' },
    { key: 'female', text: 'Femme', value: 'female' },
];

const searchModeOptions = [
    { key: 'club', text: 'Rechercher un club', value: 'club' },
    { key: 'person', text: 'Rechercher une personne', value: 'person' },
];

const getCsvUrl = (season, gender) => {
    const baseUrls = {
        '2024': {
            male: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR0w0PDulvh5c6zLSRfKhq-0JATaIAnFVIYkizbWefHfIotzRV0x5Stm2juI6_91LdM2_MAhAYbY5ge/pub?gid=1290048305&output=csv',
            female: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vR0w0PDulvh5c6zLSRfKhq-0JATaIAnFVIYkizbWefHfIotzRV0x5Stm2juI6_91LdM2_MAhAYbY5ge/pub?output=csv',
        },
        '2023': {
            male: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSj31GOuRQ2tTJ_uSQKj2L0Y5cqRceS1fw4GiHOoXVVS01RQs0MGHFqGo-EegCewKHs21ukjmiEEvdj/pub?gid=1290048305&output=csv',
            female: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSj31GOuRQ2tTJ_uSQKj2L0Y5cqRceS1fw4GiHOoXVVS01RQs0MGHFqGo-EegCewKHs21ukjmiEEvdj/pub?output=csv',
        },
    };
    return baseUrls[season]?.[gender] || baseUrls['2024']['male'];
};

const ClassementClub = () => {
    const [season, setSeason] = useState('2024');
    const [gender, setGender] = useState('male');
    const [data, setData] = useState([]);
    const [filteredClubs, setFilteredClubs] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedClub, setSelectedClub] = useState(null);
    const [searchMode, setSearchMode] = useState('club');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [membersPerPage, setMembersPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const csvUrl = getCsvUrl(season, gender);
                const response = await axios.get(csvUrl);
                const csvData = response.data;
                const lines = csvData.split('\n');
                const headers = lines[0].split(',');
                const result = [];
                for (let i = 1; i < lines.length; i++) {
                    const obj = {};
                    const currentLine = lines[i].split(',');
                    for (let j = 0; j < headers.length; j++) {
                        obj[headers[j].trim()] = currentLine[j]?.trim() || '';
                    }
                    result.push(obj);
                }
                setData(result);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchData();
    }, [season, gender]);

    const handleSearchChange = (e) => {
        setSelectedClub(null);
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        setFilteredClubs([]);
        setFilteredMembers([]);

        if (term) {
            if (searchMode === 'club') {
                const filtered = [...new Set(
                    data.filter(item =>
                        item['Club'].toLowerCase().includes(term)
                    ).map(item => item['Club'])
                )];
                setFilteredClubs(filtered);
            } else if (searchMode === 'person') {
                const members = data.filter(item =>
                    item['Prénom'].toLowerCase().includes(term) ||
                    item['Nom'].toLowerCase().includes(term)
                );
                setFilteredMembers(members);
            }
        }
    };

    const handleSearchModeChange = (e, { value }) => {
        setSearchMode(value);
        setSearchTerm('');
        setFilteredClubs([]);
        setFilteredMembers([]);
        setSelectedClub(null);
    };

    const handleClubSelect = (club) => setSelectedClub(club);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setIsModalOpen(true);
    };

    const getCategoryMembers = (category) => {
        return data.filter(item => item['Catégorie'] === category);
    };

    const selectedClubMembers = data.filter(item => item['Club'] === selectedClub);

    const lastRank = data.length;

    const getCategoryRanking = (categoryMembers) => {
        const total = categoryMembers.length; // Nombre total de membres dans la catégorie
        return categoryMembers.map((member, index) => ({
            ...member,
            CategoryRank: `${index + 1} sur ${total}`, // Place sur le total
        }));
    };

    // Pagination
    const totalPages = Math.ceil(getCategoryMembers(selectedCategory).length / membersPerPage);
    const getPaginatedData = (members) => {
        const startIndex = (currentPage - 1) * membersPerPage;
        return members.slice(startIndex, startIndex + membersPerPage);
    };

    return (
        <div>
            <Navbar>
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-12 col-md-6 offset-md-3">
                            <h1 className="text-center fs-1 mt-2 mb-4">Classement des clubs et des membres</h1>
                            <Dropdown
                                placeholder="Sélectionnez une saison"
                                fluid
                                selection
                                options={seasonOptions}
                                onChange={(e, { value }) => setSeason(value)}
                                value={season}
                                className="mb-3 mx-auto w-50"
                            />
                            <Dropdown
                                placeholder="Sélectionnez le sexe"
                                fluid
                                selection
                                options={genderOptions}
                                onChange={(e, { value }) => setGender(value)}
                                value={gender}
                                className="mb-3 mx-auto w-50"
                            />
                            <Dropdown
                                placeholder="Mode de recherche"
                                fluid
                                selection
                                options={searchModeOptions}
                                onChange={handleSearchModeChange}
                                value={searchMode}
                                className="mb-3 mx-auto w-50"
                            />
                            <Input
                                icon="search"
                                placeholder={searchMode === 'club' ? 'Rechercher un club...' : 'Rechercher un prénom ou un nom...'}
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="d-flex justify-content-center mx-auto w-50"
                            />
                        </div>
                    </div>

                    {searchMode === 'club' && filteredClubs.length > 0 && (
                        <div className="row">
                            <div className="col-12 col-md-6 offset-md-3">
                                <h3 className="text-center mt-4 title">Liste des clubs</h3>
                                <Table celled>
                                    <Table.Body>
                                        {filteredClubs.map((club, index) => (
                                            <Table.Row key={index} className="text-center">
                                                <Table.Cell onClick={() => handleClubSelect(club)} style={{ cursor: 'pointer' }}>
                                                    {club}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {searchMode === 'person' && filteredMembers.length > 0 && (
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1">
                                <h3 className="text-center mt-4 title">Membres correspondants</h3>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row className="text-center">
                                            <Table.HeaderCell>Nom Prénom</Table.HeaderCell>
                                            <Table.HeaderCell>Date de naissance</Table.HeaderCell>
                                            <Table.HeaderCell>Club</Table.HeaderCell>
                                            <Table.HeaderCell>Classement</Table.HeaderCell>
                                            <Table.HeaderCell>Médaille</Table.HeaderCell>
                                            <Table.HeaderCell>Catégorie</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {filteredMembers.map((member, index) => (
                                            <Table.Row key={index} className="text-center">
                                                <Table.Cell>{member['Nom']} {member['Prénom']}</Table.Cell>
                                                <Table.Cell>{member['Naissance']}</Table.Cell>
                                                <Table.Cell>{member['Club']}</Table.Cell>
                                                <Table.Cell>{member['Cl. Gal']} ème sur {lastRank}</Table.Cell>
                                                <Table.Cell>{member['Méd.']}</Table.Cell>
                                                <Table.Cell className='text-danger' onClick={() => handleCategoryClick(member['Catégorie'])} style={{ cursor: 'pointer' }}>
                                                    {member['Catégorie']}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {selectedClub && (
                        <div className="row">
                            <div className="col-12 col-md-10 offset-md-1">
                                <h3 className="text-center mt-4 title">Membres du club {selectedClub}</h3>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row className="text-center">
                                            <Table.HeaderCell>Nom Prénom</Table.HeaderCell>
                                            <Table.HeaderCell>Naissance</Table.HeaderCell>
                                            <Table.HeaderCell>Classement</Table.HeaderCell>
                                            <Table.HeaderCell>Médaille</Table.HeaderCell>
                                            <Table.HeaderCell>Catégorie</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {selectedClubMembers.map((member, index) => (
                                            <Table.Row key={index} className="text-center">
                                                <Table.Cell>{member['Nom']} {member['Prénom']}</Table.Cell>
                                                <Table.Cell>{member['Naissance']}</Table.Cell>
                                                <Table.Cell>{member['Cl. Gal']} ème sur {lastRank}</Table.Cell>
                                                <Table.Cell>{member['Méd.']}</Table.Cell>
                                                <Table.Cell className='text-danger' onClick={() => handleCategoryClick(member['Catégorie'])} style={{ cursor: 'pointer' }}>
                                                    {member['Catégorie']}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {isModalOpen && selectedCategory && (
                        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} className="custom-modal">
                            <Modal.Header>
                                <div className="d-flex justify-content-between w-100">
                                    <span>Membres de la catégorie {selectedCategory}</span>
                                    <Button icon="close" onClick={() => setIsModalOpen(false)} className="close-button bg-danger text-light" />
                                </div>
                            </Modal.Header>
                            <Modal.Content>
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row className="text-center">
                                            <Table.HeaderCell>Nom Prénom</Table.HeaderCell>
                                            <Table.HeaderCell>Naissance</Table.HeaderCell>
                                            <Table.HeaderCell>Place dans la catégorie</Table.HeaderCell>
                                            <Table.HeaderCell>Classement général</Table.HeaderCell>
                                            <Table.HeaderCell>Médaille</Table.HeaderCell>
                                            <Table.HeaderCell>Club</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {getPaginatedData(getCategoryRanking(getCategoryMembers(selectedCategory))).map((member, index) => (
                                            <Table.Row key={index} className="text-center">
                                                <Table.Cell>{member['Nom']} {member['Prénom']}</Table.Cell>
                                                <Table.Cell>{member['Naissance']}</Table.Cell>
                                                <Table.Cell>{member.CategoryRank}</Table.Cell>
                                                <Table.Cell>{member['Cl. Gal']} ème sur {lastRank}</Table.Cell>
                                                <Table.Cell>{member['Méd.']}</Table.Cell>
                                                <Table.Cell>{member['Club']}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        disabled={currentPage === 1}
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                    >
                                        Précédent
                                    </Button>
                                    <Button
                                        disabled={currentPage === totalPages}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                    >
                                        Suivant
                                    </Button>
                                </div>
                            </Modal.Content>
                        </Modal>
                    )}
                </div>
            </Navbar>
        </div>
    );
};

export default ClassementClub;
